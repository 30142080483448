<template>
  <div class="amazon">
    <div
      class="Details task_title"
      :style="{ backgroundImage: 'url(' + url + ')' }"
    >
      <p class="title">{{ $t("home.update[6]") }}</p>
      <div class="state">
        <span>{{ $t("shopTask[5]") }}: {{ state }}</span>
        <img
          v-if="state == $t('shopTask[8]')"
          src="../../assets/img/lock.png"
          alt=""
          width="41"
          height="55"
        />
        <img
          v-if="state == $t('shopTask[6]')"
          src="../../assets/img/jxz.png"
          alt=""
          width="41"
          height="55"
        />
        <img
          v-if="state == $t('shopTask[7]')"
          src="../../assets/img/wc.png"
          alt=""
          width="41"
          height="55"
        />
      </div>
      <div class="orderNum">
        <div>
          <p style="font-size: 16px; font-weight: 400; line-height: 19px">
            {{ $t("CoinRecharge[13]") }}
          </p>
          <p
            style="
              color: rgba(0, 0, 0, 0.5);
              font-size: 12px;
              font-weight: 400;
              line-height: 15px;
            "
          >
            {{ task_list.order_number }}
          </p>
        </div>
        <img src="../../assets/img/ztktb.png" alt="" width="28" height="32" />
      </div>
    </div>
    <!-- 中间部分 -->
    <div class="main">
      <div class="order_desc">
        <p>Detlhes da tarefa</p>
        <img :src="task_list.examine_demo" alt="" width="251" height="205" />
        <div>
          <div class="commodityID">
            <span> {{ $t("shopTask[9]") }}: </span>
            <span>{{ task_list.title }}</span>
          </div>
          <div class="CommissionAvailable">
            <span>{{ $t("shopTask[10]") }}:</span>
            <span
              >{{ Number(task_list.reward_rate).toFixed(2) }}
              {{ currency_name }}</span
            >
          </div>
        </div>
      </div>
      <!-- 商品链接 -->
      <div class="productLink">
        <p>{{ $t("shopTask[11]") }}</p>
        <a :href="task_list.link_info">{{ task_list.link_info }}</a>
      </div>
      <!-- 倒计时 -->
      <!-- <div class="countDown">
        <span>{{ countDownTime }}</span>
      </div> -->
      <!-- 支付按钮 -->
      <div
        class="pay"
        @click="支付完成任务()"
        v-if="state != $t('shopTask[8]')"
      >
        {{ $t("shopTask[12]") }}
      </div>
    </div>
    <!-- 当前页面所有的 弹窗 -->
    <van-overlay :show="show">
      <div class="wrapper">
        <!-- 完成任务的弹窗 邀请好友领现金 -->
        <div class="poPup" v-if="tc_type == '成功'">
          <div class="desc">
            <div class="img"></div>
            <div class="img1"></div>
            <div class="img2"></div>
            <div class="img3"></div>
            <div class="img4"></div>
            <div class="img5"></div>
            <div>
              <div class="title" style="overflow: hidden">
                {{ $t("TaskPopup[33]") }}
              </div>
              <!-- 佣金 账户余额 -->
              <div class="debance">
                <p>
                  {{ $t("TaskPopup[35]") }}:
                  {{ Number(tc_sj.reward_price).toFixed(2) }}
                </p>
                <p>
                  {{ $t("TaskPopup[36]") }}:
                  {{
                    (
                      Number(tc_sj.balance) +
                      Number(tc_sj.freeze_balance ? tc_sj.freeze_balance : 0)
                    ).toFixed(2)
                  }}
                </p>
                <p>
                  {{ $t("görev[27]") }}:
                  {{ Number(tc_sj.goods_price).toFixed(2) }}
                </p>
                <!-- 进度 -->
                <div class="progress">
                  <div style="width: 181px">
                    <div class="zhuxing" :style="{ width }"></div>
                    $
                  </div>
                  <div class="money_img">
                    <div class="zz" :style="{ marginLeft: width }"></div>
                  </div>
                </div>
                <div style="margin: 8px 0 0 5px; font-size: 12px">
                  {{
                    $t("TaskPopup[37]", {
                      n: tc_sj.dislog.which_one_pac,
                      m: tc_sj.dislog.success_count,
                    })
                  }}
                </div>
              </div>
            </div>
          </div>
          <!-- 底部按钮部分 -->
          <div class="buttom">
            <!-- 继续完成 -->
            <div @click="wancheng()">
              <div>{{ $t("TaskPopup[38]") }}</div>
            </div>
            <!-- 邀请好友 -->
            <div @click="$router.push('/user/promote')">
              {{ $t("TaskPopup[18]") }}
            </div>
          </div>
        </div>
        <!-- 未完成全部任务  邀请好友领取现金-->
        <incomplete v-if="false"></incomplete>
        <!-- 已完成全部任务 -->
        <div
          class="complete"
          v-if="tc_type == '已完成全部任务并且可以领取幸运包'"
        >
          <div class="title">
            <div class="jianbian ywc"></div>
            <span>{{ $t("TaskPopup[44]") }}</span>
            <div class="jianbian1 ywc"></div>
          </div>
          <div class="center">
            <img
              src="../../assets/img/gaoxing.png"
              alt=""
              width="73"
              height="86"
            />
            <div class="text">
              <span style="font-size: 12px">{{ $t("TaskPopup[45]") }}</span>
            </div>
            <p class="shibai">Task progress</p>
            <!-- 进度条 -->
            <div class="progress-bar blue stripes">
              <div style="width: 100%"></div>
            </div>
          </div>
          <!-- 按钮 -->
          <div class="button">
            <div class="completeButton" @click="luckypack()">
              {{ $t("TaskPopup[46]") }}
            </div>
            <div class="completeButton" @click="luckypack()">
              {{ $t("TaskPopup[12]") }}
            </div>
          </div>
        </div>
        <!-- 已完成全部任务 邀请好友得现金 -->
        <div class="poPup" v-if="tc_type == '已完成全部任务'">
          <!-- v-if="tc_type == '已完成全部任务'" -->
          <div class="desc">
            <div class="img"></div>
            <div class="img1"></div>
            <div class="img2"></div>
            <div class="img3"></div>
            <div class="img4"></div>
            <div class="img5"></div>
            <div>
              <div class="title">{{ $t("TaskPopup[44]") }}</div>
              <!-- 佣金 账户余额 -->
              <div class="debance">
                <p>{{ $t("TaskPopup[45]") }}</p>
                <!-- 进度 -->
                <div class="progress">
                  <div style="width: 181px">
                    <div class="zhuxing" style="width: 100%"></div>
                    {{ currency_name }}
                  </div>
                  <div class="money_img">
                    <div class="zz" style="marginleft: 100%"></div>
                  </div>
                </div>
                <div style="margin: 4px 0 0 5px">
                  {{
                    $t("TaskPopup[37]", {
                      n: tc_sj.dislog.which_one_pac,
                      m: tc_sj.dislog.success_count,
                    })
                  }}
                </div>
              </div>
            </div>
          </div>
          <!-- 底部按钮部分 -->
          <div class="buttom">
            <!-- 继续完成 -->
            <div
              @click="$router.push('/user/withdrawalCenter')"
              style="height: 45px"
            >
              <div style="height: 35px; font-size: 18px; line-height: 35px">
                {{ $t("TaskPopup[12]") }}
              </div>
            </div>
            <!-- 邀请好友 -->
            <div @click="$router.push('/user/promote')">
              <div>
                {{ $t("TaskPopup[18]") }}
              </div>
            </div>
            <div
              @click="$router.push('/')"
              style="height: 45px; font-size: 18px; line-height: 45px"
            >
              {{ $t("shopTask[16]") }}
            </div>
          </div>
        </div>
        <!-- 支付失败 邀请好友得现金-->
        <div class="pay_fail" v-if="tc_type == '失败'">
          <!-- 标题 -->
          <div class="title">{{ $t("TaskPopup[40]") }}</div>
          <div class="desc">
            <p>
              {{ $t("TaskPopup[36]") }}: {{ Number(tc_sj.balance).toFixed(2) }}
            </p>
            <p>
              {{ $t("TaskPopup[35]") }}:
              {{ Number(tc_sj.reward_price).toFixed(2) }}
            </p>
            <p>
              {{
                $t("TaskPopup[41]", {
                  n: tc_sj.dislog.which_one_pac,
                  m: Number(tc_sj.dislog.success_count) + 1,
                  y: (Number(tc_sj.code_dec) + 1).toFixed(2),
                })
              }}
            </p>
          </div>
          <!-- 进度 -->
          <div class="progress">
            <div style="width: 181px">
              <div class="zhuxing" :style="{ width }"></div>
              {{ currency_name }}
            </div>
            <div class="money_img">
              <div class="zz" :style="{ marginLeft: width }"></div>
            </div>
          </div>
          <div class="tishi">
            {{
              $t("TaskPopup[37]", {
                n: tc_sj.dislog.which_one_pac,
                m: Number(tc_sj.dislog.success_count) + 1,
              })
            }}
          </div>
          <div
            class="btn1"
            @click="
              $router.push(
                '/user/mixRecharge?m=' + (Number(tc_sj.code_dec) + 1).toFixed(2)
              )
            "
          >
            <div>
              {{ $t("TaskPopup[42]") }}
            </div>
          </div>
          <div
            class="btn2"
            @click="$router.push('/user/promote')"
            style="overflow: hidden"
          >
            {{ $t("TaskPopup[18]") }}
          </div>
        </div>
        <!-- 礼盒 2 -->
        <div
          class="giftBox"
          style="height: 380px; width: 259px"
          v-if="tc_type == '领取幸运包成功'"
        >
          <div class="top_title">{{ $t("shopTask[0]") }}</div>
          <div class="receiveTask">{{ $t("shopTask[1]") }}</div>
          <div style="margin-top: 10px; text-align: center; font-size: 14px">
            {{ $t("TaskPopup[56]") }}
          </div>
          <div class="img" style="margin-top: 0">
            <img
              src="../../assets/img/giftBox2.png"
              alt=""
              width="159"
              height="159"
            />
          </div>
          <input
            class="desc"
            style="
              width: 169px;
              height: 36px;
              border-radius: 8px;
              border: solid 1px #5e59e9;
              padding-left: 10px;
              margin-top: 4%;
            "
            :placeholder="$t('shopTask[2]')"
            v-model="qianming"
          />
          <div class="button">
            <div @click="toFinish()">{{ $t("TaskPopup[60]") }}</div>
          </div>
          <div
            style="display: flex; align-items: center; margin-top: 4px"
            class="agreement"
          >
            <input
              type="checkbox"
              ref="checked"
              @change="cc"
              style="margin-left: 10px"
            />
            <div style="">
              {{ $t("shopTask[3]")
              }}<span style="color: rgb(94, 89, 233)">{{
                $t("shopTask[4]")
              }}</span>
            </div>
          </div>
        </div>
        <!-- 高佣金任务 -->
        <comiss
          v-if="tc_type == '领取幸运包'"
          @accept="lingqu"
          @close="close"
        ></comiss>
        <!-- 正在前往亚马逊下单... -->
        <!-- <van-popup round v-model="isShowGoOrder" class="goOrder">
          正在前往亚马逊下单...
        </van-popup> -->
      </div>
    </van-overlay>
  </div>
</template>

<script>
import comiss from "../../components/tanchuang/commissionTasks.vue";
import incomplete from "../../components/tanchuang/Incomplete.vue";
//import { Toast } from 'vant';
export default {
  name: "",
  data() {
    return {
      url: "",
      show: false, //是否显示弹窗
      isShow: false, //是否显示任务
      task_list: "", //当前显示的任务信息
      加载中: "",
      tc_sj: "", //支付完成弹窗数据
      tc_type: "", //弹窗类型
      width: "5%", //弹窗进度条长度
      currency_name: JSON.parse(localStorage.getItem("InitData")).setting
        .currency_name, //货币符号
      qianming: "", //领取幸运包成功之后的弹窗 签名
      timer: null, //定时器
      time: null, //倒计时时间戳
      countDownTime: null, //倒计时
      isShowGoOrder: false, //控制正在前往亚马逊下单弹窗显示
    };
  },
  components: {
    comiss,
    incomplete,
  },
  computed: {
    //任务状态 冻结 完成 进行中
    state() {
      if (!this.task_list) return "";
      if (this.task_list.dislog.is_freeze == 1) {
        //冻结
        this.url = require("../../assets/img/dongjie.png"); //直接 用路径 vue不会处理改路径 导致路径不对 图片不显示
        return this.$t("shopTask[8]");
      } else if (this.task_list.status == 3) {
        //进行中
        this.url = require("../../assets/img/jxz.png");
        return this.$t("shopTask[6]");
      } else if (this.task_list.status == 5) {
        //已完成
        this.url = require("../../assets/img/wc.png");
        return this.$t("shopTask[7]");
      }
    },
    //图片加上前缀
    img_src() {
      return `${this.task_list.examine_demo}`;
    },
  },
  created() {
    this.加载中 = this.$toast.loading({
      message: this.$t("recharge.default[8]"),
      forbidClick: true,
      duration: 0,
    });
    this.获取下一个任务();
    // 倒计时
    // this.countDown();
  },
  methods: {
    cc() {
      console.log(this.$refs.checked.target.checked);
    },
    //高额佣金弹窗的关闭按钮 点击之后显示 签名的弹窗
    close() {
      this.tc_type = "领取幸运包成功";
    },
    //完成任务  金额不足时
    failed(data) {
      //金额不够
      //支付完成的弹窗数据
      this.tc_sj = data;
      this.show = true; //显示遮罩层
      this.tc_type = "失败"; //显示支付失败弹窗
      let whiech = Number(this.tc_sj.dislog.success_count); //当前完成的是第几个任务
      //计算进度条
      this.tc_sj.dislog.pac_done == 0 && whiech--;
      this.width = Math.round((whiech / data.totalorder) * 10000) / 100 + "%";
      if (this.width == "0%") {
        this.width = "5%";
      }
    },
    //完成任务时 判断是哪一种状态
    judgmentState(data) {
      console.log(data);
      if (
        (data.dislog.lucky_bag == 1 || data.dislog.lucky_bag == 2) &&
        data.dislog.is_success == 1
      ) {
        window.sessionStorage.setItem("getLuckyPack", 1);
        //is_success ==1 当前等级所有任务包全部完成  lucky_bag==1 幸运包已经领取过(假领取)
        this.tc_sj = data;
        //足够完成任务
        this.show = true; //显示遮罩层
        this.tc_type = "成功"; //显示成功完成弹窗
        let whiech = Number(this.tc_sj.dislog.success_count); //当前完成的是第几个任务
        //计算进度条
        this.tc_sj.dislog.pac_done == 0 && whiech--;
        this.width = Math.round((whiech / data.totalorder) * 10000) / 100 + "%";
        if (this.width == "0%") {
          this.width = "5%";
        }
      } else if (data.dislog.is_success == 1 && data.dislog.lucky_bag == 0) {
        //所有任务完成 但是不可以领取幸运包
        this.tc_sj = data;
        this.show = true; //显示遮罩层
        this.tc_type = "已完成全部任务"; //显示成功完成弹窗
      } else {
        if (data.dislog.luckypack == 1) {
          window.sessionStorage.setItem("luckypack", 1);
          //足够完成任务
          //支付完成的弹窗数据
          this.tc_sj = data;
          this.show = true; //显示遮罩层
          this.tc_type = "成功"; //显示成功完成弹窗
          let whiech = Number(this.tc_sj.dislog.success_count); //当前完成的是第几个任务
          //计算进度条
          this.tc_sj.dislog.pac_done == 0 && whiech--;
          this.width =
            Math.round((whiech / data.totalorder) * 10000) / 100 + "%";
          if (this.width == "0%") {
            this.width = "5%";
          }
          return;
        }
        //足够完成任务
        //支付完成的弹窗数据
        this.tc_sj = data;
        this.show = true; //显示遮罩层
        this.tc_type = "成功"; //显示成功完成弹窗
        let whiech = Number(this.tc_sj.dislog.success_count); //当前完成的是第几个任务
        //计算进度条
        this.tc_sj.dislog.pac_done == 0 && whiech--;
        this.width = Math.round((whiech / data.totalorder) * 10000) / 100 + "%";
        if (this.width == "0%") {
          this.width = "5%";
        }
        this.获取下一个任务();
      }
    },
    //领取幸运包任务后 去完成任务
    toFinish() {
      if (this.qianming) {
        //签名不为空 && 已经统一幸运任务领取条款
        this.show = false;
        this.tc_type = "";
        this.$Model.sign({ dislog_id: this.tc_sj.dislog.id }, (res) => {
          this.获取下一个任务();
        });
      }
    },
    lingqu() {
      this.tc_type = "领取幸运包成功";
      //lucky_bag=1
    },
    //全部任务完成 并且可以领取幸运包
    luckypack() {
      //显示高额佣金弹窗
      this.tc_type = "领取幸运包";
      //存储 dislogid
      window.localStorage.setItem("dislogid", this.tc_sj.dislog.id);
      console.log(window.localStorage.getItem("dislogid"));
    },
    // 成功任务弹窗 继续完成按钮
    wancheng() {
      //可以领取幸运包 弹出高额佣金窗口
      if (window.sessionStorage.getItem("luckypack")) {
        this.show = true;
        this.tc_type = "领取幸运包";
        window.sessionStorage.removeItem("luckypack");
        return;
      } else if (window.sessionStorage.getItem("getLuckyPack")) {
        this.$Model.getLuckyPack(
          { dislog_id: this.tc_sj.dislog.id },
          (data) => {
            if (data.status == 1) {
              //领取一下领取商城任务
              this.$Model.acceptDispatchTask((data) => {
                console.log(data);
                window.sessionStorage.removeItem("getLuckyPack");
                this.show = false;
                this.tc_type = "";
                this.获取下一个任务();
              });
            }
          }
        );
      }
      //正常的完成任务弹窗
      this.show = false;
      this.tc_type = "";
    },
    支付完成任务() {
      // 控制前往下单的显示
      // this.show = true;
      // this.isShowGoOrder = true;
      // if (this.isShowGoOrder) {
      setTimeout(() => {
        this.isShowGoOrder = false;
        this.$Dialog.Loading();
        let json = {
          task_id: this.task_list.user_task_id,
        };
        // this.task_list.dis_id !=0 现在的任务  =0 以前的任务
        if (this.task_list.dis_id == 0) {
          this.$Model.finishShopTask(json, (data) => {
            // console.log('finishShopTask', data);
            this.$Dialog.Close();

            if (data.code == 1) {
              this.judgmentState(data);
            } else if (data.code == -4) {
              this.获取下一个任务();
            } else if (data.code == -2) {
              this.failed(data);
              /*  this.$dialog({
              message:
                "O seu saldo é insuficiente para realizar a tarefa, ainda preciso de " +
                data.code_dec +
                "R$, recarregue primeiro.",
              confirmButtonText: "Recarregar",
            }).then(() => {
              this.$router.push("/user/mixRecharge");
            }); */
            }
          });
          return false;
        }
        //完成现在的任务
        this.$Model.finishDispatchTask(json, (data) => {
          this.$Dialog.Close();
          if (data.code == 1) {
            this.judgmentState(data);
          } else if (data.code == -4) {
            this.获取下一个任务();
          } else if (data.code == -2) {
            //金额不够
            this.failed(data);
          }
        });
      }, 1500);
      // }
    },
    获取下一个任务() {
      this.$Model.shopDispatchDetail((data) => {
        if (data) {
          this.time = data.count_down;
        }
        if (Object.keys(data).length > 0) {
          if (data.code == 11) {
            //当前包完成，但是当前任务流程未全部完成 抢下一个包
            this.$Model.acceptDispatchTask((data) => {
              if (data.code != 1) {
                this.$router.push("/user/Grabtask");
                return;
              }
              this.获取下一个任务();
            });
            /*  this.$dialog({
              message: this.$t("grabtask[27]"),
              confirmButtonText: this.$t("grabtask[9]"),
            }).then(() => {
              this.$router.push("/user/Grabtask");
            }); */
          } else if (data.code == 12) {
            //当前包完成，当前任务流程全部完成
            this.$router.push({
              path: "/myTask",
              query: {
                code: data.code,
              },
            });
            return;
          }
          this.task_list = data;
          this.isShow = true;
          if (data.luckypack == 1) {
            this.show = true;
            this.tc_type = "领取幸运包";
          }
          // this.加载中.clear();
        } else {
          this.$dialog({
            message: this.$t("grabtask[23]"),
            confirmButtonText: this.$t("grabtask[9]"),
          }).then(() => {
            this.$router.push({
              path: "/myTask",
              query: {
                code: 12,
              },
            });
          });
        }
        this.加载中.close();
      });
    },
    // 倒计时
    countDown() {
      this.timer = setInterval(() => {
        if (this.time === 0) {
          clearInterval(this.timer);
        } else {
          this.time--;
          let hr = parseInt((this.time / 60 / 60) % 24);
          let min = parseInt((this.time / 60) % 60);
          let sec = parseInt(this.time % 60);
          hr = hr > 9 ? hr : "0" + hr;
          min = min > 9 ? min : "0" + min;
          sec = sec > 9 ? sec : "0" + sec;
          this.countDownTime = `${hr}时${min}分${sec}秒后订单将关闭`;
        }
      }, 1000);
    },
  },
  // 离开页面清除倒计时
  beforeDestroy() {
    clearInterval(this.timer);
  },
};
</script>

<style scoped>
.amazon {
  width: 100%;
  height: max-content;
}
.Details {
  padding: 10px 10px;
  position: relative;
  z-index: 2;
  width: 100%;
  background: url("../../assets/img/jxz.png");
  background-repeat: no-repeat;
  background-size: cover;
}

.task_title {
  width: 100%;
  height: 129px;
  margin-bottom: 0 !important;
  padding: 10px 28px;
}

.task_title > .title {
  text-align: center;
  line-height: 23px;
  color: #fff;
  font-size: 22px;
}
.task_title > .state {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-top: 10px;
  color: #ffffff;

  font-size: 18px;
  font-weight: 400;
  line-height: 22px;
}
.task_title > .orderNum {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 0 10px;
  margin: 0 auto;
  margin-top: 5px;
  background: #fff;
  width: 307px;
  height: 61px;
  border-radius: 10px;
  box-shadow: 0px 0px 18px rgb(0 0 0 / 10%);
}
.main {
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
  height: calc(100vh - 129px);
  background: rgb(241, 243, 244) url("../../assets/img/sxbhd.png") no-repeat;
  background-size: cover;
}
.main > .order_desc {
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-top: 70px;
  width: 307px;
  height: 327px;
  padding: 10px 10px 0 10px;
  border-radius: 10px;
  background: #ffffff;
  box-shadow: 0px 0px 18px rgba(0, 0, 0, 0.1);
}
.main > .order_desc p {
  width: 100%;
  padding-bottom: 10px;
  margin-top: 2px;
  font-size: 18px;
  font-weight: 400;
  line-height: 21px;
}
.main > .order_desc > div,
.main > .order_desc > div > .commodityID,
.main > .order_desc > div > .CommissionAvailable {
  width: 100%;
  margin-top: 10px;
}
.main > .order_desc > div > .commodityID,
.main > .order_desc > div > .CommissionAvailable {
  display: flex;
  justify-content: space-between;
}
.main > .productLink {
  overflow: hidden;
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
  margin-top: 2%;
  width: 307px;
  height: 61px;
  color: #008fff;
  line-height: 25px;
  background: #fff;
  padding: 18px 0 0 10px;
  border-radius: 10px;
  box-shadow: 0px 0px 18px rgba(0, 0, 0, 0.1);
}
.main > .productLink p {
  margin-top: -12px;
}
.main > .pay {
  width: 155px;
  height: 45px;
  color: #ffffff;
  margin-top: 3%;
  border-radius: 10px;
  text-align: center;
  background: #028bf7;
  font-size: 21px;
  font-weight: 400;
  line-height: 45px;
}
/* 弹窗样式 */
.amazon .wrapper {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100%;
}
.wrapper > .poPup {
  width: 350px;
  height: max-content;
  display: flex;
  justify-content: center;
  flex-direction: column;
  align-items: center;
}
.wrapper > .poPup > .desc {
  position: relative;
  z-index: 9;
  display: flex;
  justify-content: center;
  width: 263px;
  height: 272px;
  border: dashed 1px #e18e4a;
  border-radius: 10px;
  background: linear-gradient(180deg, #ffe4b5, #ffe4b5, #f6c791);
  box-shadow: inset 0px 10px 13px #eb8f43;
}

.wrapper > .poPup > .desc > .img {
  width: 150px;
  height: 150px;
  position: absolute;
  top: -46%;
  left: 57%;
  background: url("../../assets/img/yanhua.png") no-repeat;
  background-size: cover;
}
.wrapper > .poPup > .desc > .img1 {
  position: absolute;
  left: -17%;
  z-index: 9;
  width: 64px;
  height: 37px;
  background: url("../../assets/img/hspzd.png") no-repeat;
  background-size: cover;
}
.wrapper > .poPup > .desc > .img2 {
  width: 50px;
  height: 30px;
  position: absolute;
  right: -17%;
  z-index: 9;
  background: url("../../assets/img/hspz.png") no-repeat;
  background-size: cover;
}
.wrapper > .poPup > .desc > .img3 {
  width: 53.21px;
  height: 50.74px;
  transform: rotate(17.67863deg);
  position: absolute;
  left: -12%;
  top: 30%;
  z-index: 9;
  background: url("../../assets/img/jbd.png") no-repeat;
  background-size: cover;
}
.wrapper > .poPup > .desc > .img4 {
  width: 39.4px;
  height: 37.55px;
  transform: rotate(-27.054363deg);
  position: absolute;
  right: -8%;
  bottom: 10%;
  background: url("../../assets/img/jbx.png") no-repeat;
  background-size: cover;
}
.wrapper > .poPup > .desc > .img5 {
  position: absolute;
  width: 390px;
  height: 149.52px;
  background: url("../../assets/img/pd.png") no-repeat;
  background-size: cover;
}
.wrapper > .poPup > .desc > div:last-child {
  margin-top: 10px;
  display: flex;
  justify-content: center;
  position: relative;
  width: 243.31px;
  height: 251.38px;
  background: linear-gradient(180deg, #ffffff, #ffffff, #ffe9d8);
  box-shadow: 0px 0.62px 4.96px #926d47;
}
.wrapper > .poPup > .desc > div > .title {
  position: absolute;
  top: -8%;
  width: 142px;
  height: 34px;
  color: #9e5029;

  font-size: 21px;
  font-weight: 400;
  line-height: 34px;
  text-align: center;
  border-radius: 10px;
  background: linear-gradient(90deg, #f5cd87, #f0b664);
  box-shadow: inset 0px 1px 1px #e6a64e;
  overflow: hidden;
}
.wrapper > .poPup > .desc > div > .debance {
  width: 100%;
  margin-top: 8%;
}
.wrapper > .poPup > .desc > div > .debance > p {
  color: #9e5029;

  font-size: 12.896831px;
  font-weight: 400;
  line-height: 35px;
  text-align: center;
}
.wrapper > .poPup > .buttom {
  display: flex;
  margin-top: -25px;
  flex-direction: column;
  justify-content: end;
  align-items: center;
  position: relative;
  width: 321px;
  height: 212px;
  background: url("../../assets/img/box.png") no-repeat center/contain;
}
.wrapper > .poPup > .buttom > div:first-child {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 247px;
  height: 58px;
  border-radius: 12px;
  border: solid #feeac5;
  background: linear-gradient(180deg, #edb45d, #eab662);
}
.wrapper > .poPup > .buttom > div:nth-child(2) {
  margin-top: 10px;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 247px;
  height: 45px;
  border-radius: 12px;
  border: solid #feeac5;
  background: linear-gradient(180deg, #edb45d, #eab662);
}
.wrapper > .poPup > .buttom > div:nth-child(2) > div {
  width: 237px;
  height: 35px;
  border-radius: 14px;
  border: solid 1px #d94a11;
  text-align: center;
  color: #ffffff;

  font-size: 18px;
  font-weight: 400;
  line-height: 35px;
  background: linear-gradient(180deg, #ffaa5a, #ff5a00);
}
.wrapper > .poPup > .buttom > div:first-child > div {
  width: 237px;
  height: 48px;
  border-radius: 14px;
  border: solid 1px #d94a11;
  text-align: center;
  color: #ffffff;

  font-size: 24.82805px;
  font-weight: 400;
  line-height: 48px;
  background: linear-gradient(180deg, #ffaa5a, #ff5a00);
}
.wrapper > .poPup > .buttom > div:last-child {
  margin-top: 10px;
  margin-bottom: 10px;
  width: 247px;
  height: 58px;
  border-radius: 12px;
  border: solid #feeac5;
  text-align: center;
  color: #ffffff;

  font-size: 24.82805px;
  font-weight: 400;
  /* line-height: 58px; */
  background: linear-gradient(180deg, #edb45d, #eab662);
}
.progress {
  display: flex;
  flex-direction: column;
  align-items: center;
}
.progress > div:first-child {
  display: flex;
  align-items: center;
}
.progress .zhuxing {
  display: inline-block;
  height: 5px;
  border-radius: 2px;
  background: #ecbd73;
}
.progress > .money_img {
  position: relative;
  width: 181px;
  height: 75.98px;
  background: url("../../assets/img/wt.png") no-repeat;
  background-size: cover;
  border-radius: 5px;
  overflow-x: hidden;
}
.progress > .money_img > div:last-child {
  width: 100%;
  height: 100%;
  background-color: rgba(38, 36, 39, 0.7);
}
.amazon .wrapper > .complete {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
  width: 279px;
  height: 341px;
  border-radius: 20px;
  background: #fff url("../../assets/img/amazon_bg.png") no-repeat;
  background-size: contain;
}
.amazon .wrapper > .giftBox {
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 259px;
  height: 312px;
  border-radius: 20px;
  border: solid 1px rgba(169, 169, 169, 0.4);
  background: #ffffff;
}
.amazon .wrapper > .giftBox > .top_title {
  position: absolute;
  top: -6%;
  width: 90%;
  min-height: 38px;
  color: #ffffff;
  text-align: center;
  font-size: 24px;
  font-weight: 400;
  line-height: 29px;
  background-image: url("../../assets/img/gongxi.png");
  background-repeat: no-repeat;
  background-size: contain;
}
.amazon .wrapper > .giftBox > .receiveTask {
  margin-top: 10%;
  color: #0e0e0e;
  font-size: 21px;
  font-weight: 400;
  line-height: 25px;
}
.amazon .wrapper > .giftBox > .img {
  margin-top: 15%;
}
.amazon .wrapper > .giftBox > .desc {
  margin-top: 8%;
  color: #0e0e0e;

  font-size: 18px;
  font-weight: 400;
  line-height: 22px;
}
.amazon .wrapper > .giftBox > .button > div {
  text-align: center;
  width: 170px;
  height: 33px;
  margin-top: 7%;
  color: #fff;
  line-height: 33px;

  font-size: 18px;
  font-weight: 400;
  border-radius: 8px;
  background: #5e59e9;
}
.amazon .wrapper > .giftBox > .agreement > div {
  overflow: hidden;
  margin-left: 5px;
  font-size: 12px;
  display: -webkit-box;
  -webkit-line-clamp: 1;
  -webkit-box-orient: vertical;
}

.amazon .wrapper > .complete > .title {
  display: flex;
  justify-content: center;
  margin-top: 5px;
  align-items: center;
  width: max-content;
  height: 33px;
  color: #ffffff;
  font-size: 24px;
  font-weight: 400;
  line-height: 29px;
}

.amazon .wrapper > .complete > .title > .jianbian {
  width: 50px;
  height: 3px;
  border-radius: 1.5px;
  background: linear-gradient(to left, #8385ff 0%, rgb(160, 158, 249) 40%);
  margin-right: 10px;
}
.amazon .wrapper > .complete > .title > .jianbian1 {
  width: 50px;
  height: 3px;
  margin-left: 10px;
  border-radius: 1.5px;
  background: linear-gradient(270deg, rgb(163, 160, 249), #8385ff);
}
.amazon .wrapper > .complete > .title > .ywc {
  width: 30px;
}
.amazon .wrapper > .complete > .title > .shibai {
  width: 60px;
}
.amazon .wrapper > .complete > .center {
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  margin-top: 60px;
  width: 239px;
  height: 146px;
  border-radius: 15px;
  background: #ffffff;
  box-shadow: 0px 1px 3px rgba(0, 0, 0, 0.17);
}
.amazon .wrapper > .complete > .shibai {
  height: 160px;
}
.amazon .wrapper > .complete > .center > p {
  margin-top: 10%;
  color: #5e59e9;
  font-size: 14px;
  font-weight: 400;
  line-height: 17px;
}
.amazon .wrapper > .complete > .center > .shibai {
  margin-top: 1%;
}
.amazon .wrapper > .complete > .center > img {
  position: absolute;
  top: -35%;
}
.amazon .wrapper > .complete > .center > .text {
  margin-top: 15%;
}
@keyframes animate-stripes {
  0% {
    background-position: 0 0;
  }
  100% {
    background-position: 60px 0;
  }
}
.amazon .wrapper > .complete > .center > .progress-bar {
  background-color: rgb(116, 147, 244);
  height: 25px;
  padding: 5px;
  width: 90%;
  margin: 10px 0 0 0;
  border-radius: 5px;
  box-shadow: inset 0px 0px 4px rgba(0, 0, 0, 0.31);
}
.amazon .wrapper > .complete > .center > .stripes div {
  background-size: 15px 15px;
  background-image: -webkit-gradient(
    linear,
    left top,
    right bottom,
    color-stop(0.25, rgba(255, 255, 255, 0.15)),
    color-stop(0.25, transparent),
    color-stop(0.5, transparent),
    color-stop(0.5, rgba(255, 255, 255, 0.15)),
    color-stop(0.75, rgba(255, 255, 255, 0.15)),
    color-stop(0.75, transparent),
    to(transparent)
  );
  background-image: -webkit-linear-gradient(
    135deg,
    rgba(255, 255, 255, 0.15) 25%,
    transparent 25%,
    transparent 50%,
    rgba(255, 255, 255, 0.15) 50%,
    rgba(255, 255, 255, 0.15) 75%,
    transparent 75%,
    transparent
  );
  background-image: -moz-linear-gradient(
    135deg,
    rgba(255, 255, 255, 0.15) 25%,
    transparent 25%,
    transparent 50%,
    rgba(255, 255, 255, 0.15) 50%,
    rgba(255, 255, 255, 0.15) 75%,
    transparent 75%,
    transparent
  );
  background-image: -ms-linear-gradient(
    135deg,
    rgba(255, 255, 255, 0.15) 25%,
    transparent 25%,
    transparent 50%,
    rgba(255, 255, 255, 0.15) 50%,
    rgba(255, 255, 255, 0.15) 75%,
    transparent 75%,
    transparent
  );
  background-image: -o-linear-gradient(
    135deg,
    rgba(255, 255, 255, 0.15) 25%,
    transparent 25%,
    transparent 50%,
    rgba(255, 255, 255, 0.15) 50%,
    rgba(255, 255, 255, 0.15) 75%,
    transparent 75%,
    transparent
  );
  background-image: linear-gradient(
    135deg,
    rgba(54, 193, 226) 25%,
    transparent 25%,
    transparent 50%,
    rgba(54, 193, 226) 50%,
    rgba(54, 193, 226) 75%,
    transparent 75%,
    transparent
  );
}
.amazon .wrapper > .complete > .center > .blue div {
  background-color: rgb(131, 230, 255);
}
.amazon .wrapper > .complete > .center > .progress-bar div {
  display: inline-block;
  height: 100%;
  border-radius: 3px;
  box-shadow: 0 1px 0 rgb(255 255 255 / 50%) inset;
  transition: width 0.4s ease-in-out;
}

.amazon .wrapper > .complete > .completeButton {
  margin-top: 30px;
  width: 125px;
  height: 33px;
  line-height: 33px;
  text-align: center;
  border-radius: 8px;
  background: #5e59e9;
  color: #ffffff;
  font-size: 18px;
  font-weight: 400;
}
.amazon .wrapper > .complete > .button {
  margin-top: 30px;
  width: 100%;
  display: flex;
  justify-content: space-evenly;
}
.amazon .wrapper > .complete > .button > div {
  height: 33px;
  color: #5e59e9;
  font-size: 18px;
  font-weight: 400;
  line-height: 33px;
  padding: 0 20px;
  border-radius: 5px;
  border: solid 1px #5e59e9;
}
.amazon .wrapper > .complete > .button > div:last-child {
  color: #fff;
  background-color: #5e59e9;
}
/* .amazon .wrapper > .commissionTasks {
  z-index: 9999;
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 357px;
  height: 357.53px;
  background: transparent url("../../assets/img/commissionTasks.png") no-repeat;
  background-size: contain;
  background-position: 8px -50px;
} */
.amazon .wrapper > .commissionTasks > .main {
  position: relative;
  width: 278px;
  height: 230px;
  border-radius: 20px;
  border: solid 5px #5e59e9;
  background-color: #fff;
  text-align: center;
}
.amazon .wrapper > .commissionTasks > .main > .top_title {
  position: absolute;
  top: -10%;
  left: 5%;
  width: 173.72px;
  height: 45px;
  border-radius: 5px;
  color: #ffffff;
  text-align: center;
  font-size: 24px;
  font-weight: 400;
  line-height: 43px;

  background: linear-gradient(270deg, #676bff, #7c89ff);
}
.amazon .wrapper > .commissionTasks > .main > .center {
  line-height: 30px;
  margin-top: 15%;
}
.amazon .wrapper > .commissionTasks > .main > .center > .rwjs {
  color: rgb(92, 88, 233);
  font-size: 24px;
  font-weight: 400;
}
.amazon .wrapper > .commissionTasks > .main > .center > .one,
.amazon .wrapper > .commissionTasks > .main > .center > .two {
  padding-left: 20px;
  text-align: left;

  font-size: 18px;
  font-weight: 400;
}
.amazon .wrapper > .commissionTasks > .main > .center > .one {
  margin-top: 5%;
}
.amazon .wrapper > .commissionTasks > .main > .center > .two {
  margin-top: 2%;
}
.amazon .wrapper > .commissionTasks > .button {
  text-align: center;
  position: absolute;
  bottom: 0;
  width: 125px;
  height: 33px;
  border-radius: 8px;
  background: #5e59e9;
  color: #ffffff;

  font-size: 24px;
  font-weight: 400;
  line-height: 29px;
}
.pay_fail {
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 321px;
  height: 600px;
  background: url("../../assets/img/zhifushibaibg.png") no-repeat center/cover;
}
.pay_fail > .title {
  margin-top: 105px;
  min-width: 142px;
  height: 34px;
  background: linear-gradient(90deg, #fae0b1, #f9ca87);
  box-shadow: inset 0px 1px 1px #e6a64e;
  color: #9e5029;

  font-size: 21px;
  font-weight: 400;
  line-height: 34px;
  text-align: center;
  border-radius: 20px;
}
.pay_fail > .desc p {
  color: #9e5029;
  margin-top: 10px;
  text-align: center;
  width: 230px;
}
.pay_fail > .tishi {
  color: #9e5029;
  font-size: 12px;
  margin-top: 10px;
}
.pay_fail > .btn1 {
  margin-top: 50px;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 247px;
  height: 58px;
  border-radius: 12px;
  border: solid #feeac5;
  background: linear-gradient(180deg, #edb45d, #eab662);
}
.pay_fail > .btn1 div {
  width: 237px;
  height: 48px;
  text-align: center;
  line-height: 48px;
  border-radius: 14px;
  border: dashed 1px #d94a11;
  background: linear-gradient(180deg, #ffaa5a, #ff5a00);
  text-shadow: 0px 0.62px 1.24px #c94700;
  color: #ffffff;

  font-size: 24.82805px;
  font-weight: 400;
}
.pay_fail > .btn2 {
  margin-top: 20px;
  width: 247px;
  height: 53px;
  border-radius: 14px;
  border: solid #feeac5;
  color: #ffffff;

  font-size: 24.82805px;
  font-weight: 400;
  line-height: 53px;
  text-align: center;
}
::v-deep .van-overlay {
  background-color: rgba(134, 134, 134, 0.9);
  z-index: 9;
}
::v-deep .van-nav-bar {
  background-color: transparent;
}
.van-nav-bar >>> .van-icon {
  color: #fff;
  font-size: 24px;
}
.van-nav-bar >>> .van-nav-bar__content {
  border-bottom-left-radius: 15px;
  border-bottom-right-radius: 15px;
  background-color: rgb(79, 81, 192);
}
::v-deep .van-nav-bar__title {
  font-size: 24px;
  color: #fff;
}
.countDown {
  margin-top: 15px;
}

/* 正在前往亚马逊下单 */
.goOrder {
  width: 360px;
  height: 200px;
  text-align: center;
  line-height: 200px;
  font-size: 20px;
}
</style>
